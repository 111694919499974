<script setup lang="ts">
const { t } = useT();

const prepareImgUrl = useImage();
</script>

<template>
	<div class="adventure">
		<div class="wrapper">
			<div>
				<picture>
					<source
						:srcset="prepareImgUrl('/nuxt-img/adventure/bg-mob.png', { format: 'webp', width: 300, height: 476 })"
						format="webp"
						width="300"
						height="476"
						media="(max-width: 767px)"
					/>
					<NuxtImg
						src="/nuxt-img/adventure/bg.jpg"
						format="webp"
						width="1108"
						height="421"
						class="back-img"
						alt="background"
						loading="lazy"
					/>
				</picture>
				<picture>
					<source
						:srcset="prepareImgUrl('/nuxt-img/adventure/leaves-mob.png', { format: 'webp', width: 300, height: 476 })"
						format="webp"
						width="300"
						height="476"
						media="(max-width: 767px)"
					/>
					<NuxtImg
						src="/nuxt-img/adventure/left-leaves.png"
						format="webp"
						width="366"
						height="420"
						class="leaves-left"
						alt="leaves"
						loading="lazy"
					/>
				</picture>
				<NuxtImg
					src="/nuxt-img/adventure/right-leaves.png"
					format="webp"
					width="285"
					height="420"
					class="leaves-right"
					alt="leaves"
					loading="lazy"
				/>
				<picture class="wheel-pic">
					<source
						:srcset="prepareImgUrl('/nuxt-img/adventure/wheels-mob.png', { format: 'webp', width: 902, height: 464 })"
						format="webp"
						width="902"
						height="464"
						media="(max-width: 767px)"
					/>
					<NuxtImg
						src="/nuxt-img/adventure/wheels.png"
						format="webp"
						width="662"
						height="418"
						class="wheel"
						alt="wheels"
						loading="lazy"
					/>
				</picture>
			</div>
			<picture>
				<source
					:srcset="prepareImgUrl('/nuxt-img/adventure/title-mob@2x.png', { format: 'webp', width: 600, height: 226 })"
					format="webp"
					width="600"
					height="226"
					media="(max-width: 767px)"
				/>
				<NuxtImg
					src="/nuxt-img/adventure/title.png"
					format="webp"
					width="745"
					height="100"
					class="title-wheel"
					loading="lazy"
				/>
			</picture>
			<div class="content">
				<div class="title-block">
					<AText variant="taiz" as="h3" :modifiers="['uppercase']" class="title">{{ t("Two extra wheels!") }}</AText>
					<AText variant="tangier" as="p" :modifiers="['bold']" class="subtitle">{{
						t("Take more with exclusive Wheels!")
					}}</AText>
				</div>
			</div>
			<div class="footer">
				<div class="right">
					<AAnimationSlide iteration-count="infinite" mode="left-to-right" :distance="4" active>
						<ASvg name="arrow-right" class="icon" />
						<ASvg name="arrow-right" class="icon" />
						<ASvg name="arrow-right" class="icon" />
						<ASvg name="arrow-right" class="icon" />
					</AAnimationSlide>
				</div>
				<AText variant="tiraspol" :modifiers="['uppercase', 'bold']">{{ t("coming soon") }}</AText>
				<div class="left">
					<AAnimationSlide iteration-count="infinite" mode="right-to-left" :distance="4" active>
						<ASvg name="arrow-left" class="icon" />
						<ASvg name="arrow-left" class="icon" />
						<ASvg name="arrow-left" class="icon" />
						<ASvg name="arrow-left" class="icon" />
					</AAnimationSlide>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">
.adventure {
	position: relative;
	height: 420px;

	@include media-breakpoint-down(lg) {
		margin: 0 gutter(2);
	}
	@include media-breakpoint-down(md) {
		height: 500px;
	}

	.back-img,
	.leaves-left,
	.leaves-right {
		position: absolute;
		top: 0;
		bottom: 0;
	}

	.back-img {
		left: 0;
		right: 0;
		width: 100%;

		@include media-breakpoint-down(md) {
			bottom: 24px;
		}
	}

	.leaves-left {
		left: 0;
		width: 337px;

		@include media-breakpoint-down(md) {
			width: 100%;
			right: 0;
			top: auto;
			height: auto;
		}
	}

	.leaves-right {
		right: 0;

		@include media-breakpoint-down(md) {
			display: none;
		}
	}

	.leaves-front {
		display: none;
		position: absolute;
		right: -28px;
		bottom: -16px;
		z-index: 1;

		@include media-breakpoint-down(md) {
			display: block;
		}
	}

	.wheel {
		position: absolute;
		right: 0;
		bottom: 0;

		@include media-breakpoint-down(xl) {
			max-width: 500px;
			height: auto;
		}

		@include media-breakpoint-down(md) {
			display: block;
			right: 50%;
			transform: translate(50%, 0);
			width: 451px;
			height: 232px;
		}
	}

	.title-wheel {
		margin: 0 auto;
		display: block;
		position: relative;
		z-index: 2;

		@include media-breakpoint-down(md) {
			width: 300px;
			height: 123px;
		}

		@include media-breakpoint-down(sm) {
			max-width: 100%;
			height: auto;
		}
	}

	.wrapper {
		position: relative;
		width: 100%;
		height: 100%;
		overflow: hidden;
		padding-top: gutter(5.5);
		padding-bottom: gutter(1);
		border-radius: 12px;
		display: flex;
		flex-direction: column;

		&::before {
			content: "";
			display: block;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			height: 127px;
			background: var(--geneve);
			z-index: 1;
		}

		@include media-breakpoint-down(md) {
			padding: gutter(3) gutter(2);
			padding-bottom: 0;

			&::before {
				height: 177px;
				background: var(--getafe);
			}
		}
	}

	.content {
		display: flex;
		justify-content: flex-start;
		position: relative;
		z-index: 2;
		flex-grow: 1;
		padding-left: gutter(22);

		@include media-breakpoint-down(xl) {
			padding-left: 0;
			align-self: center;
		}
	}

	.title-block {
		flex: 1;
		display: flex;
		flex-direction: column;
		gap: gutter(1.5);
		max-width: 442px;

		@include media-breakpoint-down(md) {
			align-items: center;
			gap: gutter(0.5);
		}
	}

	.title,
	.subtitle {
		position: relative;
		z-index: 2;
		text-shadow:
			0 10px 10px rgba(var(--corlu-rgb), 0.14),
			0 20px 25px rgba(var(--corlu-rgb), 0.21);

		@include media-breakpoint-down(md) {
			text-align: center;
		}
	}

	.title {
		@include media-breakpoint-down(md) {
			font-size: 24px;
			line-height: 30px;
			text-shadow:
				0 4px 6px rgba(var(--corlu-rgb), 0.15),
				0 10px 15px rgba(var(--corlu-rgb), 0.21);
		}
	}

	.subtitle {
		@include media-breakpoint-down(md) {
			font-size: 20px;
			line-height: 26px;
			text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
		}
	}

	.footer {
		position: relative;
		display: flex;
		justify-content: center;
		z-index: 2;
		flex-shrink: 0;

		.left,
		.right {
			display: flex;
			align-items: center;
			color: var(--cabo);
			.icon {
				font-size: 24px;
				line-height: 1;
			}
		}

		.left {
			.icon {
				margin-right: -10px;
			}
		}
		.right {
			.icon {
				margin-left: -10px;
			}
		}
	}
}
</style>
